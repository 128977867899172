<div class="d-flex flex-row align-items-center backgroundColor">
  <div class="container">
    <div class="row color">
      <div class="col-md-12 text-center">
        <span class="display-1 d-block notFoundColor">404</span>
        <div class="mb-4 lead WordColor">
          The page you are looking for was not found.
        </div>
        <button
          type="button"
          class="btn btn-light buttonColor"
          (click)="goToHome()"
        >
          Back To Home
        </button>
      </div>
    </div>
  </div>
</div>
