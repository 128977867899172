import { PublicService } from './../public.service';
import { Router } from '@angular/router';
import { Component, OnInit, NgZone } from '@angular/core';
import { auth } from 'firebase/app';
import firebase from 'firebase/app';
import swal from 'sweetalert';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
})
export class IndexComponent implements OnInit {
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private publicService: PublicService
  ) {}

  ngOnInit(): any {}
  login(): any {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }
  AuthLogin(provider): any {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let authorized = false;
        const admins = await this.publicService.getAllData('admins');
        admins.forEach((admin) => {
          if (result.user.email === admin.payload.doc.data().email) {
            authorized = true;
          }
        });
        if (authorized) {
          localStorage.setItem('user', JSON.stringify(result.user));
          localStorage.setItem('username', result.user.displayName);
          this.ngZone.run(() => {
            this.router.navigate(['admins']);
          });
        } else {
          this.callSwal('UnAuthorized!', 'You are not authorized.', 'error');
        }
      })
      .catch((error) => {
        console.log(error);
        this.callSwal('Oops!', 'Something went wrong!', 'error');
        localStorage.setItem('user', null);
      });
  }
  callSwal(title, text, icon): any {
    return swal({ title, text, icon, timer: 1500, buttons: [false] });
  }
  goToHome(): any {
    this.router.navigate(['']);
  }
}
