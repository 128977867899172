import { PublicService } from './../public.service';
import { Component, OnInit } from '@angular/core';
import { Admin } from './admin';
import swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css'],
})
export class AdminsComponent implements OnInit {
  admins = [];
  admin: Admin = new Admin();
  id = '';
  collection = 'admins';
  constructor(
    private publicService: PublicService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): any {
    this.getAllData();
  }

  getAllData(): any {
    this.spinner.show();
    this.publicService.getAllData(this.collection).then((res) => {
      this.admins = res;
      this.spinner.hide();
    });
  }
  save(): any {
    if (this.validateMail(this.admin.email)) {
      if (this.id) {
        this.spinner.show();
        this.publicService
          .updateData(this.collection, this.id, this.admin)
          .then((res) => {
            this.id = '';
            this.reset();
            this.getAllData();
            this.spinner.hide();
            this.callSwal('Good job!', 'Data Updated Successfully', 'success');
          })
          .catch((err) => {
            this.callSwal('Oops!', 'Something went wrong!', 'error');
          });
      } else {
        this.spinner.show();
        const adminObj = JSON.parse(JSON.stringify(this.admin));
        this.publicService
          .insertData(this.collection, adminObj)
          .then((res) => {
            this.reset();
            this.getAllData();
            this.spinner.hide();
            this.callSwal('Good job!', 'Data saved Successfully', 'success');
          })
          .catch((err) => {
            this.callSwal('Oops!', 'Something went wrong!', 'error');
          });
      }
    } else {
      this.callSwal('Invalid', 'Please enter a valid email', 'warning');
    }
  }
  reset(): any {
    this.admin = new Admin();
  }
  prepareToEdit(data): any {
    this.admin = data.payload.doc.data();
    this.id = data.payload.doc.id;
  }
  remove(data): any {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this data!',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Oki'],
    }).then((willDelete) => {
      if (willDelete) {
        this.publicService
          .deleteData(this.collection, data.payload.doc.id)
          .then(
            (res) => {
              this.callSwal(
                'Deleted!',
                'Data has been deleted successfully!',
                'success'
              );
              this.getAllData();
            },
            (err) => {
              this.callSwal('Oops!', 'Something went wrong!', 'error');
            }
          );
      } else {
        this.callSwal('Cancelled', 'Your data is safe :)', 'info');
      }
    });
  }
  validateMail(email): any {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  callSwal(title, text, icon): any {
    return swal({ title, text, icon, timer: 1000, buttons: [false] });
  }
}
