import { PublicService } from "./../public.service";
import { Component, OnInit } from "@angular/core";
import swal from "sweetalert";
import { NgxSpinnerService } from "ngx-spinner";
import { About } from "./about";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.css"],
})
export class AboutComponent implements OnInit {
  aboutUs = [];
  about: About = new About();
  id = "";
  collection = "aboutUs";
  constructor(
    private publicService: PublicService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.getAllData();
  }

  getAllData() {
    this.spinner.show();
    this.publicService.getAllData(this.collection).then((res) => {
      this.aboutUs = res;
      this.spinner.hide();
    });
  }
  save() {
    if (!this.about.description) {
      this.callSwal("Invalid", "Please enter description", "warning");
      return false;
    }
    if (this.id) {
      this.spinner.show();
      this.publicService
        .updateData(this.collection, this.id, this.about)
        .then((res) => {
          this.id = "";
          this.reset();
          this.getAllData();
          this.spinner.hide();
          this.callSwal("Good job!", "Data Updated Successfully", "success");
        })
        .catch((err) => {
          this.callSwal("Oops!", "Something went wrong!", "error");
        });
    } else {
      this.spinner.show();
      const adminObj = JSON.parse(JSON.stringify(this.about));
      this.publicService
        .insertData(this.collection, adminObj)
        .then((res) => {
          this.reset();
          this.getAllData();
          this.spinner.hide();
          this.callSwal("Good job!", "Data saved Successfully", "success");
        })
        .catch((err) => {
          this.callSwal("Oops!", "Something went wrong!", "error");
        });
    }
  }
  reset() {
    this.about = new About();
  }
  prepareToEdit(data) {
    this.about = data.payload.doc.data();
    this.id = data.payload.doc.id;
  }
  remove(data) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Oki"],
    }).then((willDelete) => {
      if (willDelete) {
        this.publicService
          .deleteData(this.collection, data.payload.doc.id)
          .then(
            (res) => {
              this.callSwal(
                "Deleted!",
                "Data has been deleted successfully!",
                "success"
              );
              this.getAllData();
            },
            (err) => {
              this.callSwal("Oops!", "Something went wrong!", "error");
            }
          );
      } else {
        this.callSwal("Cancelled", "Your data is safe :)", "info");
      }
    });
  }

  callSwal(title, text, icon) {
    return swal({ title, text, icon, timer: 1000, buttons: [false] });
  }
}
