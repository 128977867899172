// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAXIqjf05Q4Bq8wvTpxAC5YSAO4POC6Z8o',
    authDomain: 'kids-academy-64c17.firebaseapp.com',
    projectId: 'kids-academy-64c17',
    storageBucket: 'kids-academy-64c17.appspot.com',
    messagingSenderId: '30704830554',
    appId: '1:30704830554:web:7b2c211cb30547cffe7063',
    measurementId: 'G-X1FN262KS6',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
