import { NgxSpinnerService } from 'ngx-spinner';
import { PublicService } from './../../public.service';
import { MainInfo } from './mainInfo';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert';

@Component({
  selector: 'app-main-info',
  templateUrl: './main-info.component.html',
  styleUrls: ['./main-info.component.css'],
})
export class MainInfoComponent implements OnInit {
  contactsMainInfo = [];
  contactMainInfo: MainInfo = new MainInfo();
  id = '';
  collection = 'contactsMainInfo';
  constructor(
    private publicService: PublicService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): any {
    this.getAllData();
  }

  getAllData(): any {
    this.spinner.show();
    this.publicService.getAllData(this.collection).then((res) => {
      this.contactsMainInfo = res;
      this.contactMainInfo = res[0].payload.doc.data();
      this.id = res[0].payload.doc.id;
      this.spinner.hide();
    });
  }
  save(): any {
    if (this.validateMail(this.contactMainInfo.email)) {
      if (!this.contactMainInfo.facebookAccount) {
        this.callSwal('Invalid', 'Please enter facebook Account', 'warning');
        return false;
      }
      if (!this.contactMainInfo.instagramAccount) {
        this.callSwal('Invalid', 'Please enter instagram Account', 'warning');
        return false;
      }
      if (!this.contactMainInfo.address) {
        this.callSwal('Invalid', 'Please enter address', 'warning');
        return false;
      }
      // if (this.id) {
      this.spinner.show();
      this.publicService
        .updateData(this.collection, this.id, this.contactMainInfo)
        .then((res) => {
          this.id = '';
          // this.reset();
          this.getAllData();
          this.spinner.hide();
          this.callSwal('Good job!', 'Data Updated Successfully', 'success');
        })
        .catch((err) => {
          this.callSwal('Oops!', 'Something went wrong!', 'error');
        });
    }
    //   else {
    //     this.spinner.show();
    //     const contactMainInfoObj = JSON.parse(
    //       JSON.stringify(this.contactMainInfo)
    //     );
    //     this.publicService
    //       .insertData(this.collection, contactMainInfoObj)
    //       .then((res) => {
    //         // this.reset();
    //         this.getAllData();
    //         this.spinner.hide();
    //         this.callSwal('Good job!', 'Data saved Successfully', 'success');
    //       })
    //       .catch((err) => {
    //         this.callSwal('Oops!', 'Something went wrong!', 'error');
    //       });
    //   }
    // }
    else {
      this.callSwal('Invalid', 'Please enter a valid email', 'warning');
    }
  }
  reset(): any {
    this.contactMainInfo = new MainInfo();
  }
  prepareToEdit(data): any {
    this.contactMainInfo = data.payload.doc.data();
    this.id = data.payload.doc.id;
  }
  remove(data): any {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this data!',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Oki'],
    }).then((willDelete) => {
      if (willDelete) {
        this.publicService
          .deleteData(this.collection, data.payload.doc.id)
          .then(
            (res) => {
              this.callSwal(
                'Deleted!',
                'Data has been deleted successfully!',
                'success'
              );
              this.getAllData();
            },
            (err) => {
              this.callSwal('Oops!', 'Something went wrong!', 'error');
            }
          );
      } else {
        this.callSwal('Cancelled', 'Your data is safe :)', 'info');
      }
    });
  }
  validateMail(email): any {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  callSwal(title, text, icon): any {
    return swal({ title, text, icon, timer: 1000, buttons: [false] });
  }
}
