import { PublicService } from './public.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminPanelComponent } from './adminPanel.component';
import { FormsModule } from '@angular/forms';
import {
  COMPONENTS as AdminPanelComponents,
  AdminPanelRoutingModule,
} from './adminPanel-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatButtonModule,
} from '@angular/material';


@NgModule({
  declarations: [...AdminPanelComponents, ],
  imports: [
    CommonModule,
    AdminPanelRoutingModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatButtonModule,
    NgxSpinnerModule,
  ],
  providers: [PublicService],
  bootstrap: [AdminPanelComponent],
})
export class AdminsPanelModule {}
