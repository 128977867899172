import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
})
export class SideNavComponent implements OnInit {
  username;
  constructor(private router: Router) {}
  ngOnInit(): any {
    if (localStorage.getItem('user') === null) {
      this.router.navigate(['login']);
    } else {
      this.username = localStorage.getItem('username');
    }
  }
  logout(): any {
    localStorage.removeItem('user');
    this.router.navigate(['login']);
    // localStorage.removeItem('user');
  }
}
