<br />
<form>
  <div class="row">
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="contactMainInfo.email" name="email" />
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Address</mat-label>
        <input
          matInput
          [(ngModel)]="contactMainInfo.address"
          name="address"
          type="address"
        />
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Facebook Account</mat-label>
        <input
          matInput
          [(ngModel)]="contactMainInfo.facebookAccount"
          name="facebookAccount"
          type="facebookAccount"
        />
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Instagram Account</mat-label>
        <input
          matInput
          [(ngModel)]="contactMainInfo.instagramAccount"
          name="instagramAccount"
          type="instagramAccount"
        />
      </mat-form-field>
    </div>
  </div>
</form>
<button type="submit" class="btn btn-dark m-2" (click)="save()">Edit</button>
