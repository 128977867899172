<h3>Admins Management</h3>
<form>
  <div class="form-group">
    <mat-form-field class="w-100">
      <mat-label>Email</mat-label>
      <input
        matInput
        [(ngModel)]="admin.email"
        name="email"
        placeholder="test@gmail.com"
      />
    </mat-form-field>
  </div>
</form>
<button type="submit" class="btn btn-dark m-2" (click)="save()">Save</button>
<button type="submit" class="btn btn-secondary" (click)="reset()">Reset</button>
<table class="table table-bordered" id="table">
  <thead class="headColor">
    <tr>
      <th>Email</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let a of admins">
      <td>{{ a.payload.doc.data().email }}</td>
      <td>
        <button
          type="button"
          class="btn btnColor m-1"
          (click)="prepareToEdit(a)"
        >
          <i class="fa fa-edit"></i> Edit
        </button>
        <button type="button" class="btn btnColor" (click)="remove(a)">
          <i class="fa fa-times"></i> Delete
        </button>
      </td>
    </tr>
  </tbody>
</table>
<ngx-spinner type="ball-fussion" color="#ffca28"></ngx-spinner>
