import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(private firestore: AngularFirestore) {}

  getAllData(collection): any {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection(`${collection}`)
        .snapshotChanges()
        .subscribe((snapshots) => {
          resolve(snapshots);
        });
    });
  }
  insertData(collection, data): any {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection(`${collection}`)
        .add(data)
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }
  insertDataWithId(id, collection, data): any {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection(`${collection}`)
        .doc(id)
        .set(data)
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }
  updateData(collection, id, data): any {
    return this.firestore.collection(`${collection}`).doc(id).set(data);
  }
  deleteData(collection, id): any {
    return this.firestore.collection(`${collection}`).doc(id).delete();
  }
}
