import { Booking } from './booking';
import { Contact } from './contact';
import { PublicService } from '../public.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  url;
  panelOpenState = false;
  services = [];
  stats = [];
  testimonials = [];
  appointments = [];
  contact: Contact = new Contact();
  booking: Booking = new Booking();
  contactMainInfo;
  showSlots = false;
  selectedSlots = [];
  constructor(
    private router: Router,
    private publicService: PublicService,
    private spinner: NgxSpinnerService
  ) {
    this.url = this.router.url;
  }

  ngOnInit(): any {
    this.booking.selectedDate = '';
    this.booking.slot = '';
    this.getAll();
    this.router.events.subscribe((url) => {
      this.url = this.router.url;
    });
  }
  getAll(): any {
    this.getMainInfo();
    this.getAllFeatures();
    this.getAllTestimonials();
    this.getAllStats();
    this.getAllAppointments();
  }

  callSwal(title, text, icon): any {
    return swal({ title, text, icon, timer: 1500, buttons: [false] });
  }
  getMainInfo(): any {
    this.spinner.show();
    this.publicService.getAllData('contactsMainInfo').then((res) => {
      this.contactMainInfo = res[0].payload.doc.data();
      this.spinner.hide();
    });
  }
  getAllFeatures(): any {
    this.spinner.show();
    this.publicService.getAllData('services').then((res) => {
      this.services = res;
      this.spinner.hide();
    });
  }
  getAllStats(): any {
    this.spinner.show();
    this.publicService.getAllData('stats').then((res) => {
      this.stats = res;
      this.spinner.hide();
    });
  }
  getAllTestimonials(): any {
    this.spinner.show();
    this.publicService.getAllData('Testimonials').then((res) => {
      this.testimonials = res;
      this.spinner.hide();
    });
  }
  getAllAppointments(): any {
    this.spinner.show();
    this.publicService.getAllData('appointments').then((res) => {
      this.appointments = res;
      this.spinner.hide();
    });
  }
  sendMessage(): any {
    if (!this.contact.name) {
      this.callSwal('Invalid', 'Please enter name', 'warning');
      return false;
    }
    if (!this.validateMail(this.contact.email)) {
      this.callSwal('Invalid', 'Please enter a valid email', 'warning');
      return false;
    }
    if (!this.contact.phone) {
      this.callSwal('Invalid', 'Please enter phone', 'warning');
      return false;
    }
    if (!this.contact.message) {
      this.callSwal('Invalid', 'Please enter message', 'warning');
      return false;
    }
    this.spinner.show();
    const contactObj = JSON.parse(JSON.stringify(this.contact));
    this.publicService
      .insertData('contacts', contactObj)
      .then((res) => {
        this.reset();
        this.spinner.hide();
        this.callSwal(
          'Data saved Successfully!',
          'We will contact you as soon as possible',
          'success'
        );
      })
      .catch((err) => {
        this.callSwal('Oops!', 'Something went wrong!', 'error');
      });
  }
  validateMail(email): any {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  reset(): any {
    this.contact = new Contact();
  }
  resetBooking(): any {
    this.booking = new Booking();
    this.showSlots = false;
  }
  selectedDate(deviceValue): any {
    const slot: any = this.booking.selectedDate;
    if (slot === 'Choose Date') {
      this.showSlots = false;
    } else {
      this.selectedSlots = slot.payload.doc.data().appointments;
      this.booking.date = slot.payload.doc.id;
      this.showSlots = true;
    }
  }
  sendAppointment(): any {
    if (!this.booking.name) {
      this.callSwal('Invalid', 'Please enter name', 'warning');
      return false;
    }
    if (!this.validateMail(this.booking.email)) {
      this.callSwal('Invalid', 'Please enter a valid email', 'warning');
      return false;
    }
    if (!this.booking.phone) {
      this.callSwal('Invalid', 'Please enter phone', 'warning');
      return false;
    }
    if (!this.booking.date) {
      this.callSwal('Invalid', 'Please select date', 'warning');
      return false;
    }
    if (!this.booking.slot) {
      this.callSwal('Invalid', 'Please select slot', 'warning');
      return false;
    }
    this.spinner.show();
    delete this.booking.selectedDate;
    const bookingObj = JSON.parse(JSON.stringify(this.booking));
    this.publicService
      .insertData('bookings', bookingObj)
      .then((res) => {
        this.resetBooking();
        this.spinner.hide();
        this.callSwal(
          'Data saved Successfully!',
          'We will contact you as soon as possible',
          'success'
        );
      })
      .catch((err) => {
        this.callSwal('Oops!', 'Something went wrong!', 'error');
      });
  }
}
