import { State } from './state';
import { PublicService } from './../public.service';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css'],
})
export class StatsComponent implements OnInit {
  states = [];
  state: State = new State();
  id = '';
  collection = 'stats';
  constructor(
    private publicService: PublicService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): any {
    this.getAllData();
  }

  getAllData(): any {
    this.spinner.show();
    this.publicService.getAllData(this.collection).then((res) => {
      this.states = res;
      this.spinner.hide();
    });
  }
  save(): any {
    if (!this.state.title) {
      this.callSwal('Invalid', 'Please enter title', 'warning');
      return false;
    }
    if (!this.state.number) {
      this.callSwal('Invalid', 'Please enter number', 'warning');
      return false;
    }
    if (this.id) {
      this.spinner.show();
      this.publicService
        .updateData(this.collection, this.id, this.state)
        .then((res) => {
          this.id = '';
          this.reset();
          this.getAllData();
          this.spinner.hide();
          this.callSwal('Good job!', 'Data Updated Successfully', 'success');
        })
        .catch((err) => {
          this.callSwal('Oops!', 'Something went wrong!', 'error');
        });
    } else {
      this.spinner.show();
      const adminObj = JSON.parse(JSON.stringify(this.state));
      this.publicService
        .insertData(this.collection, adminObj)
        .then((res) => {
          this.reset();
          this.getAllData();
          this.spinner.hide();
          this.callSwal('Good job!', 'Data saved Successfully', 'success');
        })
        .catch((err) => {
          this.callSwal('Oops!', 'Something went wrong!', 'error');
        });
    }
  }
  reset(): any {
    this.state = new State();
  }
  prepareToEdit(data): any {
    this.state = data.payload.doc.data();
    this.id = data.payload.doc.id;
  }
  remove(data): any {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this data!',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Oki'],
    }).then((willDelete) => {
      if (willDelete) {
        this.publicService
          .deleteData(this.collection, data.payload.doc.id)
          .then(
            (res) => {
              this.callSwal(
                'Deleted!',
                'Data has been deleted successfully!',
                'success'
              );
              this.getAllData();
            },
            (err) => {
              this.callSwal('Oops!', 'Something went wrong!', 'error');
            }
          );
      } else {
        this.callSwal('Cancelled', 'Your data is safe :)', 'info');
      }
    });
  }

  callSwal(title, text, icon): any {
    return swal({ title, text, icon, timer: 1000, buttons: [false] });
  }
}
