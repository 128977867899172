<div class="mainContainer center">
  <i class="fa fa-forumbee logoStyle center"></i>
  <!-- <img src="/assets/images/logo.png" class="logoStyle" /> -->
  <h1 class="textAlignCenter">Admin Login</h1>
  <p class="textAlignCenter">
    You need to login with your Gmail account to be able to use The Coding
    Factory Administration Panel.
  </p>
  <div class="textAlignCenter">
    <button class="btn btn-lg btnColor" (click)="login()">Login</button>
  </div>
  <!-- <button class="btn btn-lg btnColor" (click)="goToHome()">Website</button> -->
</div>
