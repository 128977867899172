<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a
      class="nav-link active"
      id="nav-home-tab"
      data-toggle="tab"
      href="#nav-home"
      role="tab"
      aria-controls="nav-home"
      aria-selected="true"
      ><i class="fa fa-book"></i> Appointments</a
    >
    <a
      class="nav-link"
      id="nav-contact-tab"
      data-toggle="tab"
      href="#nav-contact"
      role="tab"
      aria-controls="nav-contact"
      aria-selected="false"
    >
      <i class="fa fa-user"></i> Client Appointments</a
    >
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div
    class="tab-pane fade show active"
    id="nav-home"
    role="tabpanel"
    aria-labelledby="nav-home-tab"
  >
    <app-booking-appointments></app-booking-appointments>
  </div>
  <div
    class="tab-pane fade"
    id="nav-contact"
    role="tabpanel"
    aria-labelledby="nav-contact-tab"
  >
    <app-client-booking></app-client-booking>
  </div>
</div>
