<h3>Stats Management</h3>
<form>
  <div class="row">
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>Title</mat-label>
        <input matInput [(ngModel)]="state.title" name="title" />
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>Icon</mat-label>
        <input matInput [(ngModel)]="state.icon" name="icon" type="icon" />
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>Number</mat-label>
        <input
          matInput
          [(ngModel)]="state.number"
          name="number"
          type="number"
        />
      </mat-form-field>
    </div>
  </div>
</form>
<button type="submit" class="btn btn-dark m-2" (click)="save()">Save</button>
<button type="submit" class="btn btn-secondary" (click)="reset()">Reset</button>
<table class="table table-bordered" id="table">
  <thead class="headColor">
    <tr>
      <th>Icon</th>
      <th>Title</th>
      <th>Number</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let sta of states">
      <td>{{ sta.payload.doc.data().icon }}</td>
      <td>{{ sta.payload.doc.data().title }}</td>
      <td>{{ sta.payload.doc.data().number }}</td>
      <td>
        <button
          type="button"
          class="btn btnColor m-1"
          (click)="prepareToEdit(sta)"
        >
          <i class="fa fa-edit"></i> Edit
        </button>
        <button type="button" class="btn btnColor" (click)="remove(sta)">
          <i class="fa fa-times"></i> Delete
        </button>
      </td>
    </tr>
  </tbody>
</table>
<ngx-spinner type="ball-fussion" color="#ffca28"></ngx-spinner>
