import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./adminPanel.component.html",
  styleUrls: ["./adminPanel.component.scss"],
})
export class AdminPanelComponent {
  title = "EvCars Backend";
}
