import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';
import { PublicService } from './../../public.service';
@Component({
  selector: 'app-client-messages',
  templateUrl: './client-messages.component.html',
  styleUrls: ['./client-messages.component.css'],
})
export class ClientMessagesComponent implements OnInit {
  contacts = [];
  id = '';
  collection = 'contacts';
  constructor(
    private publicService: PublicService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): any {
    this.getAllData();
  }

  getAllData(): any {
    this.spinner.show();
    this.publicService.getAllData(this.collection).then((res) => {
      this.contacts = res;
      this.spinner.hide();
    });
  }
  remove(data): any {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this data!',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Oki'],
    }).then((willDelete) => {
      if (willDelete) {
        this.publicService
          .deleteData(this.collection, data.payload.doc.id)
          .then(
            (res) => {
              this.callSwal(
                'Deleted!',
                'Data has been deleted successfully!',
                'success'
              );
              this.getAllData();
            },
            (err) => {
              this.callSwal('Oops!', 'Something went wrong!', 'error');
            }
          );
      } else {
        this.callSwal('Cancelled', 'Your data is safe :)', 'info');
      }
    });
  }
  callSwal(title, text, icon): any {
    return swal({ title, text, icon, timer: 1000, buttons: [false] });
  }
}
