<!DOCTYPE html>
<html lang="en">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>The Coding Factory</title>
    <!-- Google fonts -->
    <link
      href="//fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap"
      rel="stylesheet"
    />
    <!-- Template CSS Style link -->
    <link rel="stylesheet" href="/assets/styles/slider.css" />

    <link rel="stylesheet" href="/assets/css/style-starter.css" />
  </head>

  <body>
    <!-- header -->
    <header id="site-header" class="fixed-top">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" href="/home">
            <img src="/assets/images/logo.png" class="logoStyle" />
            <span> The Coding Factory </span>
          </a>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
            <span class="navbar-toggler-icon fa icon-close fa-times"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="/home">Home</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#features'"
                  href="javascript:void(0)"
                  >Features</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#bookings'"
                  href="javascript:void(0)"
                  >Appointments</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#stats'"
                  href="javascript:void(0)"
                  >Stats</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#testimonials'"
                  href="javascript:void(0)"
                  >Testimonials</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [ngxScrollTo]="'#contact'"
                  href="javascript:void(0)"
                  >Contacts Us</a
                >
              </li>
            </ul>
          </div>
          <!-- toggle switch for light and dark theme -->
          <!-- <div class="cont-ser-position">
            <nav class="navigation">
              <div class="theme-switch-wrapper">
                <label class="theme-switch" for="checkbox">
                  <input type="checkbox" id="checkbox" />
                  <div class="mode-container">
                    <i class="gg-sun"></i>
                    <i class="gg-moon"></i>
                  </div>
                </label>
              </div>
            </nav>
          </div> -->
          <!-- //toggle switch for light and dark theme -->
        </nav>
      </div>
    </header>
    <!-- //header -->

    <!-- banner section -->
    <section id="home" class="w3l-banner py-5">
      <div class="banner-content">
        <div class="container py-4">
          <div class="row align-items-center pt-sm-5 pt-4">
            <div class="col-md-6">
              <h3 class="mb-lg-4 mb-3">
                Your Kids <br />Deserve The<span class="d-block"
                  >Best Education</span
                >
              </h3>
              <p class="banner-sub">
                Active Learning, Expert Teachers & Safe Environment
              </p>
            </div>
            <div
              class="col-md-6 right-banner-2 text-end position-relative mt-md-0 mt-5"
            >
              <div class="sub-banner-image mx-auto">
                <img
                  src="/assets/images/banner.png"
                  class="img-fluid position-relative"
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- //banner section -->

    <!-- home 4grids block -->
    <section class="services-w3l-block py-5" id="features">
      <div class="container py-md-5 py-4">
        <div
          class="title-main text-center mx-auto mb-md-5 mb-4"
          style="max-width: 500px"
        >
          <h3 class="title-style">Do More With The Coding Factory</h3>
        </div>
        <div class="row">
          <div
            *ngFor="let ser of services"
            class="col-md-6 col-lg-4 d-flex align-items-stretch"
          >
            <div class="icon-box icon-box-clr-1">
              <div class="icon">
                <i class="fas fa-{{ ser.payload.doc.data().icon }}"></i>
              </div>
              <h4 class="title">
                <a>{{ ser.payload.doc.data().title }}</a>
              </h4>
              <p>
                {{ ser.payload.doc.data().description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- home 4grids block -->

    <!-- home image with content block -->
    <section class="w3l-servicesblock pt-lg-5 pt-4 pb-5 mb-lg-5" id="bookings">
      <div class="container pb-md-5 pb-4">
        <div
          class="title-main text-center mx-auto mb-md-5 mb-4"
          style="max-width: 500px"
        >
          <h3 class="title-style">Book An Appointment</h3>
        </div>
        <div class="row pb-xl-5 align-items-center">
          <div
            class="col-lg-6 position-relative home-block-3-left pb-lg-0 pb-5"
          >
            <div class="position-relative">
              <img
                src="/assets/images/img1.jpg"
                alt=""
                class="img-fluid radius-image"
              />
            </div>
          </div>
          <div class="col-xl-5 col-lg-6 offset-xl-1 mt-lg-0 mt-5 pt-lg-0 pt-5">
            <h4 class="title-style">
              Contact us to discuss how The Coding Factory can help you enhance
              your child's skills
            </h4>
            <div class="row contact-block">
              <form class="signin-form">
                <div class="input-grids">
                  <div class="row">
                    <div class="col-sm-12">
                      <input
                        type="text"
                        placeholder="Your Name"
                        class="contact-input"
                        name="bookingName"
                        id="bookingName"
                        [(ngModel)]="booking.name"
                      />
                    </div>
                    <div class="col-sm-6">
                      <input
                        type="email"
                        placeholder="Your Email"
                        class="contact-input"
                        name="bookingEmail"
                        id="bookingEmail"
                        [(ngModel)]="booking.email"
                      />
                    </div>
                    <div class="col-sm-6">
                      <input
                        type="number"
                        placeholder="Your Phone"
                        class="contact-input"
                        name="bookingPhone"
                        id="bookingPhone"
                        [(ngModel)]="booking.phone"
                      />
                    </div>
                    <div class="col-sm-6">
                      <select
                        id="inputState"
                        class="contact-input"
                        [(ngModel)]="booking.selectedDate"
                        name="date"
                        (change)="selectedDate($event.target.value)"
                      >
                        <option selected value="">Choose Date</option>
                        <option *ngFor="let a of appointments" [ngValue]="a">
                          {{ a.payload.doc.id }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-6" *ngIf="showSlots">
                      <select
                        id="slot"
                        class="contact-input"
                        [(ngModel)]="booking.slot"
                        name="slot"
                      >
                        <option selected value="">Choose Date</option>
                        <option *ngFor="let appoint of selectedSlots">
                          From
                          {{ appoint.sessionStartTime }}
                          To
                          {{ appoint.sessionEndTime }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="text-start center">
                  <button
                    class="btn btn-style btn-style-3"
                    (click)="sendAppointment()"
                  >
                    Send Appointment
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- //home image with content block -->

    <!-- stats block -->
    <section class="w3-stats pt-4 pb-5" id="stats">
      <div class="container pb-md-5 pb-4">
        <div
          class="title-main text-center mx-auto mb-md-5 mb-4"
          style="max-width: 500px"
        >
          <h5 class="title-style">
            At the Coding Factory, subscribers don't just learn to code They
            also develop communication skills to excel in their various fields
          </h5>
        </div>
        <div class="row text-center pt-4">
          <div *ngFor="let state of stats" class="col-md-3 col-6">
            <div class="counter">
              <div class="icon">
                <i class="fas fa-{{ state.payload.doc.data().icon }}"></i>
              </div>
              <div
                class="timer count-title count-number mt-sm-1"
                data-to="36076"
                data-speed="1500"
              ></div>
              <p class="count-text">{{ state.payload.doc.data().title }}</p>
              <p class="count-text">{{ state.payload.doc.data().number }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- //stats block -->

    <!-- testimonials block -->
    <section class="w3l-index4 py-5" id="testimonials">
      <div class="container py-md-5 py-4">
        <div class="content-slider text-center">
          <div class="clients-slider">
            <div class="mask">
              <ul>
                <span *ngFor="let test of testimonials; let idx = index">
                  <li class="anim{{ idx + 1 }}">
                    <img
                      src="/assets/images/user.png"
                      class="img-fluid rounded-circle"
                      alt="client image"
                    />
                    <blockquote class="quote">
                      <q>{{ test.payload.doc.data().message }} </q>
                    </blockquote>
                    <div class="source">
                      - {{ test.payload.doc.data().name }}
                    </div>
                  </li>
                </span>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- //testimonials block -->
    <section class="w3l-contact py-5" id="contact">
      <div class="container py-md-5 py-4">
        <div
          class="title-main text-center mx-auto mb-md-5 mb-4"
          style="max-width: 500px"
        >
          <p class="text-uppercase">Get In Touch</p>
          <h3 class="title-style">Contact Us</h3>
        </div>
        <div class="row contact-block">
          <div class="col-md-7 contact-right">
            <form
              action="https://sendmail.w3layouts.com/submitForm"
              method="post"
              class="signin-form"
            >
              <div class="input-grids">
                <div class="row">
                  <div class="col-sm-12">
                    <input
                      type="text"
                      name="contactName"
                      id="contactName"
                      placeholder="Your Name"
                      class="contact-input"
                      [(ngModel)]="contact.name"
                    />
                  </div>
                  <div class="col-sm-6">
                    <input
                      type="email"
                      name="contactMail"
                      id="contactMail"
                      placeholder="Your Email"
                      class="contact-input"
                      [(ngModel)]="contact.email"
                    />
                  </div>
                  <div class="col-sm-6">
                    <input
                      type="number"
                      name="contactPhone"
                      id="contactPhone"
                      placeholder="Your Phone"
                      class="contact-input"
                      [(ngModel)]="contact.phone"
                    />
                  </div>
                </div>
              </div>
              <div class="form-input">
                <textarea
                  name="contactMessage"
                  id="contactMessage"
                  placeholder="Type your message here"
                  [(ngModel)]="contact.message"
                ></textarea>
              </div>
              <div class="text-start">
                <button
                  class="btn btn-style btn-style-3"
                  (click)="sendMessage()"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
          <div class="col-md-5 ps-lg-5 mt-md-0 mt-5">
            <div class="contact-left">
              <div class="cont-details">
                <div class="d-flex contact-grid">
                  <div class="cont-left text-center me-3">
                    <i class="fas fa-building"></i>
                  </div>
                  <div class="cont-right">
                    <h6>Company Address</h6>
                    <p>{{ contactMainInfo?.address }}</p>
                  </div>
                </div>
                <div class="d-flex contact-grid mt-4 pt-lg-2">
                  <div class="cont-left text-center me-3">
                    <i class="fas fa-envelope-open-text"></i>
                  </div>
                  <div class="cont-right">
                    <h6>Email Us</h6>
                    <p>
                      <a
                        href="mailto:{{ contactMainInfo?.email }}"
                        class="mail"
                        >{{ contactMainInfo?.email }}</a
                      >
                    </p>
                  </div>
                </div>
                <div class="d-flex contact-grid mt-4 pt-lg-2">
                  <div class="cont-left text-center me-3">
                    <i class="fab fa-facebook"></i>
                  </div>
                  <div class="cont-right">
                    <h6>Facebook</h6>
                    <p>
                      <a
                        href="{{ contactMainInfo?.facebookAccount }}"
                        target="_blank"
                      >
                        Facebook Account</a
                      >
                    </p>
                  </div>
                </div>
                <div class="d-flex contact-grid mt-4 pt-lg-2">
                  <div class="cont-left text-center me-3">
                    <i class="fab fa-instagram"></i>
                  </div>
                  <div class="cont-right">
                    <h6>Instagram</h6>
                    <p>
                      <a
                        href="{{ contactMainInfo?.instagramAccount }}"
                        target="_blank"
                      >
                        Instagram Account</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- footer block -->
    <footer class="w3l-footer-29-main">
      <p class="copy-footer-29 text-center pt-lg-2 mt-5 pb-2">
        © 2022 The Coding Factory. All rights reserved.
      </p>
    </footer>
    <!-- //footer block -->

    <!-- Js scripts -->
    <!-- move top -->
    <button onclick="topFunction()" id="movetop" title="Go to top">
      <span class="fas fa-level-up-alt" aria-hidden="true"></span>
    </button>
    <script>
      // When the user scrolls down 20px from the top of the document, show the button
      window.onscroll = function () {
        scrollFunction();
      };

      function scrollFunction() {
        if (
          document.body.scrollTop > 20 ||
          document.documentElement.scrollTop > 20
        ) {
          document.getElementById("movetop").style.display = "block";
        } else {
          document.getElementById("movetop").style.display = "none";
        }
      }

      // When the user clicks on the button, scroll to the top of the document
      function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    </script>
    <!-- //move top -->

    <!-- common jquery plugin -->
    <script src="/assets/js/jquery-3.3.1.min.js"></script>
    <!-- //common jquery plugin -->

    <!-- /counter-->
    <script src="/assets/js/counter.js"></script>
    <!-- //counter-->

    <!-- theme switch js (light and dark)-->
    <script src="/assets/js/theme-change.js"></script>
    <!-- //theme switch js (light and dark)-->

    <!-- MENU-JS -->
    <script>
      $(window).on("scroll", function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 80) {
          $("#site-header").addClass("nav-fixed");
        } else {
          $("#site-header").removeClass("nav-fixed");
        }
      });

      //Main navigation Active Class Add Remove
      $(".navbar-toggler").on("click", function () {
        $("header").toggleClass("active");
      });
      $(document).on("ready", function () {
        if ($(window).width() > 991) {
          $("header").removeClass("active");
        }
        $(window).on("resize", function () {
          if ($(window).width() > 991) {
            $("header").removeClass("active");
          }
        });
      });
    </script>
    <!-- //MENU-JS -->

    <!-- disable body scroll which navbar is in active -->
    <script>
      $(function () {
        $(".navbar-toggler").click(function () {
          $("body").toggleClass("noscroll");
        });
      });
    </script>
    <!-- //disable body scroll which navbar is in active -->

    <!-- bootstrap -->
    <script src="/assets/js/bootstrap.min.js"></script>
    <!-- //bootstrap -->
    <!-- //Js scripts -->
  </body>
</html>
