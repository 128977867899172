<h3>Testimonial Management</h3>
<form>
  <div class="row">
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="testimonial.name" name="name" />
      </mat-form-field>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label for="description">Message</label>
        <textarea
          class="form-control"
          id="message"
          [(ngModel)]="testimonial.message"
          name="message"
          rows="4"
        ></textarea>
      </div>
    </div>
  </div>
</form>
<button type="submit" class="btn btn-dark m-2" (click)="save()">Save</button>
<button type="submit" class="btn btn-secondary" (click)="reset()">Reset</button>
<table class="table table-bordered" id="table">
  <thead class="headColor">
    <tr>
      <th>Name</th>
      <th>Message</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let test of Testimonials">
      <td>{{ test.payload.doc.data().name }}</td>
      <td>{{ test.payload.doc.data().message }}</td>
      <td>
        <button
          type="button"
          class="btn btnColor m-1"
          (click)="prepareToEdit(test)"
        >
          <i class="fa fa-edit"></i> Edit
        </button>
        <button type="button" class="btn btnColor" (click)="remove(test)">
          <i class="fa fa-times"></i> Delete
        </button>
      </td>
    </tr>
  </tbody>
</table>
<ngx-spinner type="ball-fussion" color="#ffca28"></ngx-spinner>

