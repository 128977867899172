import { BookingsComponent } from './bookings/bookings.component';
import { StatsComponent } from './stats/stats.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { AboutComponent } from './about/about.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ServicesComponent } from './services/services.component';
import { NotFoundComponent } from './../not-found/not-found.component';
import { AdminPanelComponent } from './adminPanel.component';
import { AdminsComponent } from './admins/admins.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingAppointmentsComponent } from './bookings/booking-appointments/booking-appointments.component';
import { ClientBookingComponent } from './bookings/client-booking/client-booking.component';
import { MainInfoComponent } from './contacts/main-info/main-info.component';
import { ClientMessagesComponent } from './contacts/client-messages/client-messages.component';

const routes: Routes = [
  {
    path: 'admins',
    component: AdminPanelComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'admins', component: AdminsComponent },
      { path: 'bookings', component: BookingsComponent },
      { path: 'services', component: ServicesComponent },
      { path: 'stats', component: StatsComponent },
      { path: 'contacts', component: ContactsComponent },
      { path: 'about', component: AboutComponent },
      { path: 'testimonials', component: TestimonialComponent },
      { path: '**', component: NotFoundComponent },
    ],
  },
];

export const COMPONENTS = [
  AdminPanelComponent,
  HomeComponent,
  AdminsComponent,
  ServicesComponent,
  ContactsComponent,
  AboutComponent,
  TestimonialComponent,
  SideNavComponent,
  StatsComponent,
  BookingsComponent,
  BookingAppointmentsComponent,
  ClientBookingComponent,
  MainInfoComponent,
  ClientMessagesComponent,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AdminPanelRoutingModule {}
