<br />
<h3>Appointments Management</h3>
<form>
  <div class="row">
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>Date</mat-label>
        <input
          matInput
          type="date"
          [(ngModel)]="appointment.date"
          name="date"
          [disabled]="editMode"
        />
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>Start Time</mat-label>
        <input
          matInput
          type="time"
          [(ngModel)]="appointment.sessionStartTime"
          name="sessionStartTime"
          type="sessionStartTime"
        />
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="w-100">
        <mat-label>End Time</mat-label>
        <input
          matInput
          type="time"
          [(ngModel)]="appointment.sessionEndTime"
          name="sessionEndTime"
          type="sessionEndTime"
        />
      </mat-form-field>
    </div>
  </div>
</form>
<button type="submit" class="btn btn-dark m-2" (click)="save()">Save</button>
<button type="submit" class="btn btn-secondary" (click)="reset()">Reset</button>
<table class="table table-bordered" id="table">
  <thead class="headColor">
    <tr>
      <th>Date</th>
      <th>Appointments</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let a of appointments">
      <td>{{ a.payload.doc.id | date }}</td>
      <td>
        <span
          *ngFor="
            let appoint of a.payload.doc.data().appointments;
            let idx = index
          "
        >
          Slot
          <span class="redColor">
            {{ idx + 1 }}
          </span>
          From
          <span class="redColor">
            {{ appoint.sessionStartTime }}
          </span>
          To
          <span class="redColor">
            {{ appoint.sessionEndTime }}
          </span>
          <!-- -
          <span class="">
            {{ appoint.reserved ? "Reserved" : "Not Reserved" }}
          </span>
          <span class="redColor">
            {{ appoint.reservedBy ? "By" : "" }}
          </span>
          <span class="">
            {{ appoint.reservedBy ? appoint.reservedBy : "" }}
          </span> -->
          <br />
        </span>
      </td>
      <td>
        <button
          type="button"
          class="btn btnColor m-1"
          (click)="prepareToEdit(a)"
        >
          <i class="fa fa-edit"></i> Add More Slots
        </button>
        <button type="button" class="btn btnColor" (click)="remove(a)">
          <i class="fa fa-times"></i> Delete
        </button>
      </td>
    </tr>
  </tbody>
</table>
<ngx-spinner type="ball-fussion" color="#ffca28"></ngx-spinner>
