<!-- <nav class="navbar navbar-light bg-light">
  <a class="navbar-brand"></a>
  <form class="form-inline">
    <span class="mr-3"> Welcome {{ username }} </span>
    <a class="logoutStyle" (click)="logout()"
      ><i class="fa fa-sign-out"></i> Logout
    </a>
  </form>
</nav> -->
<div class="sidebar">
  <div class="navbar-brand">
    <!-- <img src="/assets/images/logo.png" class="logoStyle" /> -->
    <i class="fa fa-forumbee logoStyle"></i>
  </div>
  <h5 class="title">
    <span> Welcome {{ username }} </span>
  </h5>
  <!-- <h5 class="nameStyle"> -->
  <!-- <i class="fa fa-user-circle-o"></i> -->
  <!-- </h5> -->
  <hr class="hrStyle" />
  <a
    class="nav-link"
    [routerLink]="['/admins/home']"
    [routerLinkActive]="['active']"
    ><i class="fa fa-tachometer mr-2"></i> Dashboard
    <span class="sr-only">(current)</span></a
  >
  <a
    class="nav-link"
    [routerLink]="['/admins/admins']"
    [routerLinkActive]="['active']"
    ><i class="fa fa-user-secret mr-2"></i> Admins <span class="sr-only"></span
  ></a>
  <a
    class="nav-link"
    [routerLink]="['/admins/services']"
    [routerLinkActive]="['active']"
    ><i class="fa fa-tasks mr-2"></i> Features <span class="sr-only"></span
  ></a>
  <a
    class="nav-link"
    [routerLink]="['/admins/bookings']"
    [routerLinkActive]="['active']"
    ><i class="fa fa-book mr-2"></i> Appointments <span class="sr-only"></span
  ></a>
  <a
    class="nav-link"
    [routerLink]="['/admins/stats']"
    [routerLinkActive]="['active']"
    ><i class="fa fa-gift mr-2"></i> Stats <span class="sr-only"></span
  ></a>
  <!-- <a
    class="nav-link"
    [routerLink]="['/admins/about']"
    [routerLinkActive]="['active']"
    ><i class="fa fa-address-card mr-2"></i> About Us
    <span class="sr-only"></span
  ></a> -->
  <a
    class="nav-link"
    [routerLink]="['/admins/testimonials']"
    [routerLinkActive]="['active']"
    ><i class="fa fa-quote-left mr-2"></i> Testimonials
    <span class="sr-only"></span
  ></a>
  <a
    class="nav-link"
    [routerLink]="['/admins/contacts']"
    [routerLinkActive]="['active']"
    ><i class="fa fa-users mr-2"></i> Contact Us <span class="sr-only"></span
  ></a>
  <hr class="hrStyle" />
  <a class="nav-link logoutStyle" (click)="logout()"
    ><i class="fa fa-sign-out"></i> Logout <span class="sr-only"></span
  ></a>
</div>
