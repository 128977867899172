<br />
<table class="table table-bordered" id="table">
  <thead class="headColor">
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th>Phone</th>
      <th>Date</th>
      <th>Slot</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let contact of bookings">
      <td>{{ contact.payload.doc.data().name }}</td>
      <td>{{ contact.payload.doc.data().email }}</td>
      <td>{{ contact.payload.doc.data().phone }}</td>
      <td>{{ contact.payload.doc.data().date }}</td>
      <td>{{ contact.payload.doc.data().slot }}</td>
      <td>
        <button type="button" class="btn btnColor" (click)="remove(contact)">
          <i class="fa fa-times"></i> Delete
        </button>
      </td>
    </tr>
  </tbody>
</table>
<ngx-spinner type="ball-fussion" color="#ffca28"></ngx-spinner>
