import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AdminsPanelModule } from "./AdminPanel/adminPanel.module";
import { environment } from "./../environments/environment";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AngularFireStorage } from "@angular/fire/storage";
import { NgxSpinnerModule } from "ngx-spinner";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { PublicService as AdminPanelService } from "./AdminPanel/public.service";
import { PublicService as HomeService } from "./public.service";
import { AgmCoreModule } from "@agm/core";
import {MatExpansionModule} from '@angular/material/expansion';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { IndexComponent } from './index/index.component';

@NgModule({
  declarations: [AppComponent, HomeComponent, NotFoundComponent, IndexComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AdminsPanelModule,
    NgxSpinnerModule,
    ScrollToModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBfze1M5a3MgR5b_kxmtCZCWPvQyfOWROQ",
      libraries: ["places"],
    }),
  ],
  providers: [AngularFireStorage, AdminPanelService, HomeService],
  bootstrap: [AppComponent],
})
export class AppModule {}
