import { Appointment } from './appointment';
import { PublicService } from './../../public.service';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-booking-appointments',
  templateUrl: './booking-appointments.component.html',
  styleUrls: ['./booking-appointments.component.css'],
})
export class BookingAppointmentsComponent implements OnInit {
  appointments = [];
  appointment: Appointment = new Appointment();
  id = '';
  collection = 'appointments';
  editMode = false;
  constructor(
    private publicService: PublicService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): any {
    this.getAllData();
  }

  getAllData(): any {
    this.spinner.show();
    this.publicService.getAllData(this.collection).then((res) => {
      this.appointments = res;
      this.spinner.hide();
    });
  }
  save(): any {
    if (!this.appointment.sessionStartTime) {
      this.callSwal('Invalid', 'Please enter start date', 'warning');
      return false;
    }
    if (!this.appointment.sessionEndTime) {
      this.callSwal('Invalid', 'Please enter end date', 'warning');
      return false;
    }
    if (this.id) {
      const newObj = {
        reserved: false,
        reservedBy: '',
        sessionStartTime: this.appointment.sessionStartTime,
        sessionEndTime: this.appointment.sessionEndTime,
      };
      const newArray = [...this.appointment.appointments, newObj];
      const obj = {
        appointments: newArray,
      };
      const appointmentObj = JSON.parse(JSON.stringify(obj));
      this.spinner.show();
      this.publicService
        .updateData(this.collection, this.id, appointmentObj)
        .then((res) => {
          this.id = '';
          this.editMode = false;
          this.reset();
          this.getAllData();
          this.spinner.hide();
          this.callSwal('Good job!', 'Data Updated Successfully', 'success');
        })
        .catch((err) => {
          this.callSwal('Oops!', 'Something went wrong!', 'error');
        });
    } else {
      if (!this.appointment.date) {
        this.callSwal('Invalid', 'Please select date', 'warning');
        return false;
      }
      this.spinner.show();
      const allData = [
        {
          reserved: false,
          reservedBy: '',
          sessionStartTime: this.appointment.sessionStartTime,
          sessionEndTime: this.appointment.sessionEndTime,
        },
      ];
      const obj = {
        appointments: allData,
      };
      const appointmentObj = JSON.parse(JSON.stringify(obj));
      this.publicService
        .insertDataWithId(
          this.appointment.date,
          this.collection,
          appointmentObj
        )
        .then((res) => {
          this.reset();
          this.getAllData();
          this.spinner.hide();
          this.callSwal('Good job!', 'Data saved Successfully', 'success');
        })
        .catch((err) => {
          console.log(err);

          this.callSwal('Oops!', 'Something went wrong!', 'error');
        });
    }
  }
  reset(): any {
    this.appointment = new Appointment();
    this.id = '';
    this.editMode = false;
  }
  prepareToEdit(data): any {
    this.appointment = data.payload.doc.data();
    this.editMode = true;
    this.id = data.payload.doc.id;
  }
  remove(data): any {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this data!',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Oki'],
    }).then((willDelete) => {
      if (willDelete) {
        this.publicService
          .deleteData(this.collection, data.payload.doc.id)
          .then(
            (res) => {
              this.callSwal(
                'Deleted!',
                'Data has been deleted successfully!',
                'success'
              );
              this.getAllData();
            },
            (err) => {
              this.callSwal('Oops!', 'Something went wrong!', 'error');
            }
          );
      } else {
        this.callSwal('Cancelled', 'Your data is safe :)', 'info');
      }
    });
  }
  callSwal(title, text, icon): any {
    return swal({ title, text, icon, timer: 1000, buttons: [false] });
  }
}
